import './style.css'
import ChatBox from './ChatBox';
import { useState } from 'react';

function Chat() {
    const [messages, setMessages] = useState([]);

    function handleMessageSend(message) {
        setMessages(prevMessages => [...prevMessages, message]);
    }

    return messages.length === 0 ? (
        <div className="chat-container">
            <div className="chat-messages">
                <p> No messages. </p>
            </div>
            <ChatBox onSend={handleMessageSend}/>
        </div>
    ) : (
        <div className="chat-container">
        <div className="chat-messages">
        {messages.map((message, index) => (
                    <p key={index}>{message}</p>
                ))}
        </div>
        <ChatBox onSend={handleMessageSend}/>
    </div>
    )
}

export default Chat;