import './style.css'
import { useState } from 'react';
import Dropzone from 'react-dropzone';


function VideoPlayer() {
    const [video, setVideo] = useState(null);

    if (video !== null) {
        console.log(video);
        console.log(video.path)
        return (
            <div className="video-container">
                <video controls loop width="100%" height="100%" volume="0.15">
                    <source src={video} type="video/mp4"/>
                    <p>
                        Your browser doesn't support HTML videos.
                    </p>
                </video>
            </div>
        );
    }
    else {
        return (
            <div className="video-container">
                <Dropzone onDrop={acceptedFiles => {
                    const file = acceptedFiles[0];

                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const videoSrc = event.target.result;

                        setVideo(videoSrc);
                    }

                    reader.readAsDataURL(file);
                }}>
                    {({getRootProps, getInputProps}) => (
                        <section className='drop-video'>
                        <div {...getRootProps()} className='drop-video'>
                            <input {...getInputProps()} />
                            <p>To start a room, drop your video here.</p>
                        </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }
}

export default VideoPlayer;