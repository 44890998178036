import './style.css';
import { useState } from 'react';


function ChatBox( {onSend} ) {
    const [inputValue, setInputValue] = useState('');

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }
    
    function handleSend()
    {
        alert('add');
        // send to backend api in the future
        onSend(inputValue);
    }

    return (    
    <div className='chat-box'>
        <input type='text' className='message-content' onChange={handleInputChange}></input>
        <button className='send-message' onClick={handleSend}>Send</button>
    </div>
    );
}

export default ChatBox;