import './style.css';

function NavigationBar() {
    return (
        <div id="nav-bar">
            <div className='title'>noah's streaming page</div>
        </div>
    )
}

export default NavigationBar;