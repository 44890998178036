import NavigationBar from './NavigationBar';
import VideoPlayer from './VideoPlayer';
import Chat from './Chat';
import './style.css';

function App() {
  return (
    <div>
        <NavigationBar />
        <div className="container">   
            <VideoPlayer />
            <Chat />
        </div>
    </div>
  );
}

export default App;
